import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GlobalVariable } from '../global';
import { UserApiService } from '../services/user-api.service';
declare var google;

@Component({
  selector: 'app-track-order',
  templateUrl: './track-order.page.html',
  styleUrls: ['./track-order.page.scss'],
})
export class TrackOrderPage implements OnInit {
  @ViewChild('map') mapElement: ElementRef;
  @ViewChild('order')order;
  longitude: number=34.0934823
  latitude: number=70.3242344
  map: any;
  mapInterVal:any;
  firstTime=false;
  orderid:any;
  marker: any;
  notReady=false;
  message:any;
  refreshing=false;
  userMarker:any;
  delivered=false;
  constructor(
    private server:UserApiService,
    private modalCtrl:ModalController,
    public globals:GlobalVariable
  ) {
   
   }
   ionViewWillEnter(){
   

   }
   ionViewDidLeave(){
     console.log('leaving')
     clearInterval(this.mapInterVal)
   }

  ngOnInit() {
    console.log(this.order);
    this.orderid=this.order.o_id;
    this.getStatus(this.orderid);
    this.mapInterVal=setInterval(()=>{
      this.getStatus(this.orderid);
    },10000)
  }
  close(){
    this.modalCtrl.dismiss()
    
  }
  getStatus(oid){
    this.server.getOrderStatus(oid).subscribe(res=>{
      console.log(res);
      if(res.success){
        this.notReady=false;
        this.delivered=res.delivered;
       let coords= res.location.split(',');
       this.latitude=parseFloat(coords[0]);
       this.longitude=parseFloat(coords[1]);
       if(!this.firstTime){
         this.firstTime=true;
        this.loadMap();
       }
       else{
        // this.addMarker(this.latitude,this.this.latitude);
        let latLng = new google.maps.LatLng(this.latitude, this.longitude);
        this.marker.setPosition(latLng)
       }
        

      }
      else{
        this.delivered=res.delivered;
        this.notReady=true;
        this.message=res.message;
        // this.loadMap();
      }

    },err=>{
this.globals.presentToast('hold on! Network error')
    })

  }


  loadMap() {
    console.log(this.mapElement);
    let mapOptions = {
      center: {
        lat: this.latitude,
        lng: this.longitude,
      },
      fullscreenControlOptions: false,
      mapTypeControl: false,
      zoom: 14,
      streetViewControl: false,
      zoomControl: true,
      fullscreenControl: false,
      keyboardShortcuts: false,
      scrollwheel: true,
      scaleControl: false,
      draggable: true,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };
    console.log(this.latitude, this.longitude);
    this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
    this.addMarker(this.latitude, this.longitude);
  }

  addMarker(lat: number, lng: number) {
    console.log('Marker Here', lat, lng);
    let latLng = new google.maps.LatLng(lat, lng);
    this.marker = new google.maps.Marker({
      map: this.map,
      animation: google.maps.Animation.DROP,
      position: latLng,
      draggable:false,
      icon: 'assets/img/marker.png',
    });
    latLng = new google.maps.LatLng(31.4830431, 74.3962326);
    this.userMarker = new google.maps.Marker({
      map: this.map,
      animation: google.maps.Animation.DROP,
      position: latLng,
      draggable:false,
      icon: 'assets/img/usermarker.png',
    });
    // this.marker.setPosition(latLng);
    //  google.maps.event.addListener(marker, 'dragend',()=>{
    //   console.log('drag marker ',marker.getPosition())
     
    //   });

    let content = 'Rider'; //add username here

    this.addInfoWindow(this.marker, content);
 
  }

  addInfoWindow(marker, content) {
    let infoWindow = new google.maps.InfoWindow({
      content: content,
    });

    google.maps.event.addListener(marker, 'click', () => {
      infoWindow.open(this.map, marker);
    });
  }
  refresh(){
    document.getElementById('rotate').classList.remove('rotate');
    // this.refreshing=false;
    // this.refreshing=true;
    setTimeout(() => {
    document.getElementById('rotate').classList.add('rotate');
      
    }, 1000);


    this.getStatus(this.orderid);
  }
}
