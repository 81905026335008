import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { IonInput, ModalController } from '@ionic/angular';
import { GlobalVariable } from '../global';

@Component({
  selector: 'app-custom-tip',
  templateUrl: './custom-tip.page.html',
  styleUrls: ['./custom-tip.page.scss'],
})
export class CustomTipPage implements OnInit {
  @ViewChild(IonInput, { static: false }) input: IonInput;
  @Input('tip') tip;
  active = 'dollar';
  tipType = 'dollar';
  tipAmount;
  constructor(
    private modalController: ModalController,
    private globals: GlobalVariable
  ) {}

  ngOnInit() {}
  ionViewDidEnter() {
    if (this.tip) {
      this.tipAmount = this.tip;
    }

    setTimeout(() => {
      this.input.setFocus();
    }, 150);
  }
  close() {
    this.modalController.dismiss();
  }
  segmentChanged(e) {
    console.log(e.detail.value);
    this.tipAmount = 0;
    setTimeout(() => {
      this.input.setFocus();
    }, 150);
    this.active = e.detail.value;
    if (this.active == 'dollar') {
      this.tipType = 'dollar';
    } else {
      this.tipType = 'perc';
    }
  }
  addTip() {
    // if (!this.tipAmount) {
    //   this.globals.presentToast('Select Tip First');
    //   return;
    // }
    // let tipData = {
    //   tip: this.tipAmount,
    //   type: this.tipType,
    // };
    // this.modalController.dismiss(tipData);

    if (
      this.tipAmount === null ||
      this.tipAmount === undefined ||
      this.tipAmount === ''
    ) {
      this.globals.presentToast('Select Tip First');
      return;
    }
    let tipData = {
      tip: this.tipAmount,
      type: this.tipType,
    };

    this.modalController.dismiss(tipData);
  }
}
