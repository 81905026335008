import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GlobalVariable } from '../global';

@Component({
  selector: 'app-reward-notification',
  templateUrl: './reward-notification.page.html',
  styleUrls: ['./reward-notification.page.scss'],
})
export class RewardNotificationPage implements OnInit {
  @Input() data: any;
  @Input() callFrom: any;
  constructor(
    public modalController: ModalController,
    public globals: GlobalVariable
  ) {}

  ngOnInit() {
    console.log('Helllo', this.callFrom);
  }
  close() {
    this.modalController.dismiss();
  }
}
