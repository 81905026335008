import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GlobalVariable } from '../global';
import { UserApiService } from '../services/user-api.service';

@Component({
  selector: 'app-dinein-table',
  templateUrl: './dinein-table.page.html',
  styleUrls: ['./dinein-table.page.scss'],
})
export class DineinTablePage implements OnInit {
  activeTable;
  roomsList=[]
  tablesList=[]
  activeRoomId
  selectedTable:any
  loading=false;
  activeRoom
  constructor(private modalCtrl:ModalController,private server:UserApiService,public globals:GlobalVariable) {
    this.getRooms()
   }

  ngOnInit() {

  }
  close(){
    this.modalCtrl.dismiss()
  }
  getRooms(){
    this.loading=true;
    // this.globals.loader();
    this.server.getRooms().subscribe(res=>{
      // this.globals.dismiss();
    this.loading=false;

      console.log(res)
      if(res.success){
        this.roomsList=res.rooms
        if(this.roomsList.length>0){
          this.activeRoomId=this.roomsList[0].id;
          this.activeRoom=this.roomsList[0]

          this.tablesList=this.roomsList[0].tables
          console.log(this.tablesList);
        }
      }
    },err=>{
      // this.globals.dismiss();
    this.loading=false;
      
      this.globals.presentToast('Network Error')

    })
  }
  segmentChanged(e){
    console.log(e.detail)
    this.roomsList.forEach(room=>{
      if(room.id==e.detail.value){
        this.tablesList=room.tables;
        this.activeRoomId=room.id;
        this.activeRoom=room;
      }
    })
  }
  selectTable(table){
    console.log(table);
    if(table.status!='vacant'){
      return
    }
    this.activeTable=table.id
    this.selectedTable=table;
    // this.modalCtrl.dismiss(table);

  }
  saveTable(){
    
    if(this.selectedTable){
    this.loading=true;
      this.server.checkTableStatus(this.selectedTable.id).subscribe(res=>{
    this.loading=false;

        console.log(res)
        if(res.success){
          if(res.is_vacant){
    this.modalCtrl.dismiss({table:this.selectedTable,room:this.activeRoom});


          }
          else{
            
              this.activeTable=undefined;
              this.globals.presentToast('The Desired table is not available or already occupied.')
            
          }
        }
        
      },err=>{
    this.loading=false;

      })
    // this.modalCtrl.dismiss({table:this.selectedTable});

    }
  }
}
